import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
  ActivitiesTimelineActions,
  AuthAmplifyHubActions,
  AuthAPIActions,
  AuthCapacitorActions,
  AuthEffectOperatorActions,
  AuthEffectsActions,
  AuthEmailConfirmActions,
  AuthEmailConfirmSettingsActions,
  AuthEmailSettingsActions,
  AuthForgotPasswordActions,
  AuthForgotPasswordEmailConfirmActions,
  AuthLaunchActions,
  AuthPasswordConfirmSettingsActions,
  AuthPasswordSettingsActions,
  AuthPhoneConfirmActions,
  AuthSelectors,
  AuthSettingsActions,
  AuthSignInActions,
  AuthSignUpActions,
  DiscoverViewActions,
  FavouritesListActions,
  GamesListActions,
  HomePageActions,
  NavigationSelectors,
  PortfolioActions,
  PushNotificationsAPIActions,
  PushNotificationsPinpointActions,
  User,
  UsersApiActions,
  UsersOnboardingUsernameActions,
} from '@yeekatee/core-data-access';
import { AnalyticsService } from '@yeekatee/core-util-analytics';
import { ErrorType } from '@yeekatee/shared-util-errors';
import { NavigationRouteNames } from '@yeekatee/shared-util-routes';
import { filterNil } from '@yeekatee/shared-util-rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs';
import { AuthService } from '../services';

@Injectable()
export class AuthEffects implements OnInitEffects {
  /**
   * When the user is not authenticated, and tries to run an effect which
   * requires authentication, this will enter the {@link authenticateRetry()}
   * operator, which will dispatch this action, and redirect to the auth page.
   */
  saveRouteAndRequestAuthWhenAttemptingUnauthorizedAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthEffectOperatorActions.authRequiredToRunEffect),
      switchMap(() =>
        this.store.select(NavigationSelectors.selectUrl).pipe(
          take(1),
          map((lastRoute) => AuthEffectsActions.redirectToAuth({ lastRoute })),
        ),
      ),
    ),
  );

  routeToAuthPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthEffectsActions.redirectToAuth,
          HomePageActions.redirectToAuth,
          ActivitiesTimelineActions.redirectToAuth,
          PortfolioActions.redirectToAuth,
          GamesListActions.redirectToAuth,
          FavouritesListActions.redirectToAuth,
          DiscoverViewActions.redirectToAuth,
        ),
        tap(() =>
          this.navController.navigateRoot(['/', NavigationRouteNames.AUTH]),
        ),
      ),
    { dispatch: false },
  );

  /**
   * The custom OAuth state is a stringified JSON object, that we parse here
   * to extract the last route the user was trying to access, before being
   * redirected to the auth page.
   */
  parseCustomOAuthState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAmplifyHubActions.customOAuthState),
      map(({ customState }) => {
        try {
          const obj = JSON.parse(customState);
          return AuthEffectsActions.parsedCustomOAuthStateSuccess({
            lastRoute: obj.lastRoute,
          });
        } catch (error) {
          return AuthEffectsActions.parsedCustomOAuthStateFailure({ error });
        }
      }),
    ),
  );

  /**
   * A first attempt at retrieving the current user, from the local store.
   * This can happen immediately, as soon as the effect is loaded,
   * so that we may have a something in the store by the time the guard fires.
   */
  getCurrentUserFromCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthEffectsActions.onInitEffects),
      switchMap(() =>
        this.authService.getCurrentUser(false).pipe(
          map((user) => AuthEffectsActions.getCachedUserSuccess({ user })),
          catchError((error) =>
            of(AuthEffectsActions.getCachedUserFailure({ error })),
          ),
        ),
      ),
    ),
  );

  /**
   * We have to distinguish here between when we need a user refresh
   * for authentication purposes, so that we may then conclude by routing,
   * and when we just changed some values related to the auth user,
   * and want to ensure that the cached token is also refreshed
   * (this is out of control from the store, we must trigger Amplify to do so),
   * so that a subsequent refresh will give us the right value,
   * when fetching data locally.
   *
   * It's a bit convolute, but I couldn't quite figure out a better way
   * to handle it: basically, what happens here is that we may have to
   * refresh the auth user for multiple reasons:
   *
   * *  {@link AuthLaunchActions}`.launchInit`:
   *    happens when the *cached access token* expired.
   *    The guard will then initially redirect back to `/auth`,
   *    that action will dispatch, and now we attempt to refresh credentials,
   *    and if successful, route back to where the user intended to go.
   * *  {@link AuthAPIActions}`.confirmSignInSuccess`:
   *    we are in `/auth`, the user concluded their sign in,
   *    and now we can again fetch the credentials,
   *    and redirect them back where they wanted to go.
   *
   * This is covered by this first effect. More specifically, the routing is
   * executed by another effect {@link redirectToHomeOrLastRoute$} that listens
   * on {@link AuthEffectsActions}`.getCurrentUserSuccess`
   * (among other actions, that also signal a successful sign in).
   *
   * @see refreshLocalUserBehindTheScenes$
   * @see redirectToHomeOrLastRoute$
   */
  refreshLocalUserThenRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLaunchActions.launchInit, AuthAPIActions.confirmSignInSuccess),
      exhaustMap(() =>
        this.authService.getCurrentUser().pipe(
          map((user) =>
            AuthEffectsActions.getCurrentUserSuccess({
              user,
            }),
          ),
          catchError((error) =>
            of(
              AuthEffectsActions.getCurrentUserFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * The second case, by this effect, is slightly different.
   *
   * Here, the user just updated some values (that may need to be reflected
   * in the *cached ID token*). Behind the scenes, we want then to signal
   * Amplify to do it, so that on a next sign in (it may really mean just
   * closing and reopening the app), when we don't have to
   * *refresh the access and ID token*, we do not get stale data.
   *
   * @see refreshLocalUserThenRoute$
   */
  refreshLocalUserBehindTheScenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UsersApiActions.updateUserSuccess,
        AuthAmplifyHubActions.autoSignInSuccess,
      ),
      exhaustMap(() =>
        this.authService.getCurrentUser().pipe(
          map((user) =>
            AuthEffectsActions.refreshCurrentUserSuccess({
              user,
            }),
          ),
          catchError((error) =>
            of(
              AuthEffectsActions.refreshCurrentUserFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * @see refreshLocalUserThenRoute$
   */
  redirectToHomeOrLastRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthAPIActions.confirmPasswordSuccess,
          AuthEffectsActions.getCurrentUserSuccess,
          AuthEffectsActions.federatedSignInSuccess,
          AuthAPIActions.signInSuccess,
          AuthAmplifyHubActions.autoSignInSuccess,
          AuthEffectsActions.parsedCustomOAuthStateSuccess,
        ),
        withLatestFrom(this.store.select(AuthSelectors.selectLastRoute)),
        tap(([, lastRoute]) =>
          /**
           * It must get a string and not an array due to
           * https://github.com/angular/angular/issues/28917#issuecomment-1614672077
           */
          this.navController.navigateRoot(lastRoute ?? '/'),
        ),
      ),
    { dispatch: false },
  );

  configureAnalytics$ = createEffect(() =>
    this.store.select(AuthSelectors.selectUser).pipe(
      filter((user): user is User => !!user?.sub),
      switchMap((user) =>
        this.store.select(NavigationSelectors.selectUrl).pipe(
          filterNil(),
          take(1),
          map((lastRoute) =>
            from(this.analyticsService.configureAnalytics(user, lastRoute)),
          ),
          map(() => AuthAPIActions.configureAnalyticsSuccess()),
        ),
      ),
      catchError((error) =>
        of(AuthAPIActions.configureAnalyticsFailure({ error })),
      ),
    ),
  );

  loadUsernameFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLaunchActions.toSignIn),
      exhaustMap(() =>
        this.authService.getStoredUsername().pipe(
          map((username) =>
            AuthCapacitorActions.loadUsernameSuccess({
              username,
            }),
          ),
          catchError((error) =>
            of(
              AuthCapacitorActions.loadUsernameFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthSignUpActions.signUp),
      exhaustMap(({ credentials }) =>
        this.authService.signUp(credentials).pipe(
          map(({ user, codeDeliveryDetails }) =>
            AuthAPIActions.signUpSuccess({
              user: {
                email: credentials.email,
                id: user.getUsername(),
                challengeName: codeDeliveryDetails.Destination,
              },
            }),
          ),
          catchError((error) => of(AuthAPIActions.signUpFailure({ error }))),
        ),
      ),
    ),
  );

  resendSignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthEmailConfirmActions.resendCode,
        AuthAPIActions.signInUserNotConfirmed, // ? TODO When is this dispatched?
      ),
      withLatestFrom(this.store.select(AuthSelectors.selectUser)),
      filter(([, user]) => !!user?.id),
      exhaustMap(([, user]) =>
        // Safe code as non-null check is done in previous filter
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.authService.resendSignUp(user!.id!).pipe(
          map(() => AuthAPIActions.resendSignUpSuccess()),
          catchError((error) =>
            of(AuthAPIActions.resendSignUpFailure({ error })),
          ),
        ),
      ),
    ),
  );

  confirmSignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthEmailConfirmActions.verifyCode),
      distinctUntilChanged(
        (previous, current) => previous.code === current.code,
      ),
      withLatestFrom(this.store.select(AuthSelectors.selectUser)),
      filter(([, user]) => !!user?.id),
      exhaustMap(([{ code }, user]) =>
        // Safe code as non-null check is done in previous filter
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.authService.confirmSignUp(user!.id!, code).pipe(
          map(() => AuthAPIActions.confirmSignUpSuccess()),
          catchError((error) =>
            of(AuthAPIActions.confirmSignUpFailure({ error })),
          ),
        ),
      ),
    ),
  );

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthSignInActions.signIn),
      exhaustMap(({ credentials }) =>
        this.authService.signIn(credentials).pipe(
          map(({ cognitoUser, groups }) => {
            if (cognitoUser.challengeName) {
              return AuthAPIActions.signInPresentedChallenge({
                name: cognitoUser.challengeName,
                parameters: (cognitoUser as any).challengeParam,
              });
            }

            const user = {
              ...(cognitoUser as any).attributes,
              groups,
              id: cognitoUser.getUsername(),
            } as User;
            return AuthAPIActions.signInSuccess({ user });
          }),
          catchError((error) =>
            error.code === ErrorType.UserNotConfirmedException
              ? of(
                  AuthAPIActions.signInUserNotConfirmed({
                    username: credentials.username,
                  }),
                )
              : of(AuthAPIActions.signInFailure({ error })),
          ),
        ),
      ),
    ),
  );

  confirmSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPhoneConfirmActions.verifyCode),
      exhaustMap(({ code }) =>
        this.authService.confirmSignIn(code).pipe(
          map(() => AuthAPIActions.confirmSignInSuccess()),
          catchError((error) => {
            return of(AuthAPIActions.confirmSignInFailure({ error }));
          }),
        ),
      ),
    ),
  );

  googleSignIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthLaunchActions.signUpWithGoogle,
          AuthSignInActions.signInWithGoogle,
        ),
        concatLatestFrom(() =>
          this.store.select(AuthSelectors.selectRedirectCustomState),
        ),
        exhaustMap(([, customState]) =>
          from(this.authService.googleSignIn(customState)),
        ),
      ),
    { dispatch: false },
  );

  appleSignIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthLaunchActions.signUpWithApple,
          AuthSignInActions.signInWithApple,
        ),
        concatLatestFrom(() =>
          this.store.select(AuthSelectors.selectRedirectCustomState),
        ),
        exhaustMap(([, customState]) =>
          from(this.authService.appleSignIn(customState)),
        ),
      ),
    { dispatch: false },
  );

  federatedSignInCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthCapacitorActions.federatedSignInCallback),
      switchMap(({ callbackUrl }) =>
        this.authService.handleFederatedSignInCallback(callbackUrl).pipe(
          map((user) => AuthEffectsActions.federatedSignInSuccess({ user })),
          catchError((error) =>
            of(AuthEffectsActions.federatedSignInFailure({ error })),
          ),
        ),
      ),
    ),
  );

  signOutOnNoUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthEffectsActions.notifyError),
      filter(({ error }) => error?.message == 'No current user'),
      map(() => AuthEffectsActions.noCurrentUserDetected()),
    ),
  );

  onSignOutWaitForCleanup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UsersApiActions.deleteUserSuccess,
        AuthSettingsActions.signOut,
        UsersOnboardingUsernameActions.signOut,
      ),
      switchMap(() =>
        forkJoin([
          this.actions$.pipe(
            ofType(PushNotificationsPinpointActions.endpointInactivateSuccess),
            take(1),
          ),
          this.actions$.pipe(
            ofType(PushNotificationsAPIActions.deviceUnregistrationSuccess),
            take(1),
          ),
        ]).pipe(
          timeout(5000),
          map(() => AuthEffectsActions.signOutCleanupSuccess()),
          catchError((error) =>
            of(AuthEffectsActions.signOutCleanupFailure({ error })),
          ),
        ),
      ),
    ),
  );

  finaliseSignOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthEffectsActions.signOutCleanupSuccess,
        AuthEffectsActions.signOutCleanupFailure, // ? TODO Maybe we should log this in Sentry?
        AuthEffectsActions.noCurrentUserDetected,
        // ! This caused issues at boot, when it would clean up the store immediately since the user was not signed in
        // AuthEffectsActions.getCurrentUserFailure,
        AuthEffectsActions.refreshCurrentUserFailure,
      ),
      exhaustMap(() =>
        this.authService.signOut().pipe(
          map(() => AuthAPIActions.signOutSuccess()),
          catchError((error) => of(AuthAPIActions.signOutFailure({ error }))),
        ),
      ),
    ),
  );

  restartApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthAPIActions.signOutSuccess, AuthAPIActions.signOutFailure),
        // We can't just reload the page, or Ionic will lose the navigation stack
        tap(() => location.assign('/')),
      ),
    { dispatch: false },
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthForgotPasswordActions.resetPassword,
        AuthPasswordSettingsActions.resetPassword,
      ),
      exhaustMap(({ credentials }) =>
        this.authService.resetPassword(credentials.username).pipe(
          map(({ CodeDeliveryDetails: { Destination } }) => {
            const destination = Destination;
            return AuthAPIActions.resetPasswordSuccess({ destination });
          }),
          catchError((error) =>
            of(AuthAPIActions.resetPasswordFailure({ error })),
          ),
        ),
      ),
    ),
  );

  resendPasswordConfirmCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthForgotPasswordEmailConfirmActions.resendCode,
        AuthPasswordConfirmSettingsActions.resendCode,
      ),
      withLatestFrom(this.store.select(AuthSelectors.selectUpdatedCredentials)),
      exhaustMap(([, credentials]) =>
        credentials && credentials.username
          ? this.authService.resetPassword(credentials.username).pipe(
              map(({ CodeDeliveryDetails: { Destination } }) => {
                const destination = Destination;
                return AuthAPIActions.resetPasswordSuccess({ destination });
              }),
              catchError((error) =>
                of(AuthAPIActions.resetPasswordFailure({ error })),
              ),
            )
          : of(
              AuthAPIActions.resetPasswordFailure({
                error: new Error('missing credentials to reset password'),
              }),
            ),
      ),
    ),
  );

  confirmPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthForgotPasswordEmailConfirmActions.verifyCode),
      withLatestFrom(this.store.select(AuthSelectors.selectUpdatedCredentials)),
      exhaustMap(([{ code }, credentials]) =>
        credentials
          ? this.authService.confirmPassword(credentials, code).pipe(
              map(() => {
                return AuthAPIActions.confirmPasswordSuccess();
              }),
              catchError((error) => {
                return of(AuthAPIActions.confirmPasswordFailure({ error }));
              }),
            )
          : of(
              AuthAPIActions.confirmPasswordFailure({
                error: new Error('new credentials missing'),
              }),
            ),
      ),
    ),
  );

  confirmPasswordSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPasswordConfirmSettingsActions.verifyCode),
      distinctUntilChanged(
        (previous, current) => previous.code === current.code,
      ),
      withLatestFrom(this.store.select(AuthSelectors.selectUpdatedCredentials)),
      exhaustMap(([{ code }, credentials]) =>
        credentials
          ? this.authService.confirmPassword(credentials, code).pipe(
              map(() => {
                return AuthAPIActions.confirmPasswordSettingsSuccess();
              }),
              catchError((error) => {
                return of(
                  AuthAPIActions.confirmPasswordSettingsFailure({ error }),
                );
              }),
            )
          : of(
              AuthAPIActions.confirmPasswordFailure({
                error: new Error('new credentials missing'),
              }),
            ),
      ),
    ),
  );

  // TODO sign in after password reset: AuthAPIActions.confirmPasswordSuccess,

  setEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthEmailSettingsActions.setEmail),
      switchMap(({ user }) =>
        this.authService.setEmail(user?.email).pipe(
          map(() => AuthAPIActions.setEmailSuccess()),
          catchError((error) => of(AuthAPIActions.setEmailFailure({ error }))),
        ),
      ),
    ),
  );

  requestEmailConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthEmailConfirmSettingsActions.requestNewEmailCode),
      withLatestFrom(this.store.select(AuthSelectors.selectUser)),
      switchMap(([, user]) =>
        this.authService.setEmail(user?.email).pipe(
          map(() => AuthAPIActions.setEmailSuccess()),
          catchError((error) => of(AuthAPIActions.setEmailFailure({ error }))),
        ),
      ),
    ),
  );

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthEmailConfirmSettingsActions.verifyCode),
      distinctUntilChanged(
        (previous, current) => previous.code === current.code,
      ),
      switchMap(({ code }) =>
        this.authService.confirmAttribute('email', code).pipe(
          map(() => AuthAPIActions.confirmEmailSuccess()),
          catchError((error) =>
            of(AuthAPIActions.confirmEmailFailure({ error })),
          ),
        ),
      ),
    ),
  );

  refreshLocalUserThenRouteBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAPIActions.confirmEmailSuccess),
      exhaustMap(() =>
        this.authService.getCurrentUser().pipe(
          map((user) =>
            AuthEffectsActions.refreshCurrentUserRouteBackSuccess({
              user,
            }),
          ),
          catchError((error) =>
            of(
              AuthEffectsActions.refreshCurrentUserRouteBackFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  routeBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthEffectsActions.refreshCurrentUserRouteBackSuccess,
          AuthAPIActions.confirmPasswordSettingsSuccess,
        ),
        tap(() => this.navController.back()),
      ),
    { dispatch: false },
  );

  triggerAppInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthAmplifyHubActions.signInSuccess,
        AuthAmplifyHubActions.autoSignInSuccess,
        AuthEffectsActions.federatedSignInSuccess,
        AuthEffectsActions.getCachedUserSuccess,
      ),
      map((action) =>
        AuthEffectsActions.appInit({
          userId: 'userId' in action ? action.userId : action.user.id,
        }),
      ),
    ),
  );

  handleError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthEffectsActions.refreshCurrentUserFailure,
        AuthAPIActions.signUpFailure,
        AuthAPIActions.resendSignUpFailure,
        AuthAPIActions.signInFailure,
        AuthAPIActions.confirmSignInFailure,
        AuthAPIActions.confirmPasswordFailure,
        AuthAPIActions.confirmPasswordSettingsFailure,
        AuthAmplifyHubActions.autoSignInFailure,
        AuthEffectsActions.federatedSignInFailure,
        AuthEffectsActions.refreshCurrentUserRouteBackFailure,
      ),
      map((error) => AuthEffectsActions.notifyError({ error })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly navController: NavController,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngrxOnInitEffects = () => AuthEffectsActions.onInitEffects();
}
