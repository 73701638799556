import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { match } from '@formatjs/intl-localematcher';

import { Store } from '@ngrx/store';
import {
  AvailableLocale,
  availableLocales,
  isSupportedLocale,
  localeLookupMap,
  LocalesActions,
} from '@yeekatee/core-data-access';
import { Settings } from 'luxon';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalesService {
  /**
   * The key used in Capacitor Preferences to store the user's preferred locale.
   * @private
   */
  private static readonly PREFERENCES_PREFFERED_LOCALE = 'preferredLocale';

  public readonly defaultLocale: AvailableLocale = 'en-US';

  constructor(private readonly store: Store) {}

  /**
   * ! This "global" state is nasty but apparently unavoidable.
   * Because only APP_INITIALIZER can be asynchronous,
   * only then can we retrieve the value with async/await.
   * We then set this value here, so that when we then need to inject LOCALE_ID
   * - it must always happen after APP_INITIALIZER - we get it from this var.
   *
   * @private
   * @see https://github.com/angular/angular/issues/23279
   */
  private asyncLoadedLocale?: AvailableLocale;
  get locale(): AvailableLocale {
    // ! If for any reason the value is unset, we must return the default locale
    return this.asyncLoadedLocale ?? this.defaultLocale;
  }

  /**
   * Get the locale as set on the device OS settings, retrieved by Capacitor.
   *
   * The device locale could very well be not an AvailableLocale.
   */
  async getDeviceLocale(): Promise<string> {
    const languageTag = await Device.getLanguageTag();
    return languageTag.value;
  }

  /**
   * Persist the preferred locale in the Preferences.
   *
   * ! This will restart the app if reload is true.
   *
   * @param locale a Locale we support
   * @param reload if true, this restarts the application!
   *
   * @throws if locale is not a supported locale
   */
  async setPreferredLocale(
    locale: AvailableLocale,
    reload: boolean,
  ): Promise<AvailableLocale> {
    await Preferences.set({
      key: LocalesService.PREFERENCES_PREFFERED_LOCALE,
      value: locale.toString(),
    });

    this.reloadIfRequested(reload);

    return locale;
  }

  /**
   * Remove the preferred locale from the Preferences,
   * it will then default to the device locale.
   *
   * ! This will restart the app if reload is true.
   *
   * @param reload if true, this restarts the application!
   */
  async unsetPreferredLocale(reload: boolean): Promise<void> {
    await Preferences.remove({
      key: LocalesService.PREFERENCES_PREFFERED_LOCALE,
    });

    this.reloadIfRequested(reload);
  }

  /**
   * ! We need to reload the app to actually show the change
   *
   * @param doReload if true, this function will restart the whole app!
   * @private
   */
  private reloadIfRequested(doReload: boolean) {
    if (doReload) {
      // We can't just reload the page, or Ionic will lose the navigation stack
      location.assign('/');
    }
  }

  /**
   * Get the locale set by the user in the Preferences.
   *
   * It SHOULD be a supported locale, however,
   * for testing purposes we now allow any value to be set.
   * This means that by changing `CapacitorStorage.preferredLocale` in
   * the browser local storage we can emulate the test cases.
   *
   * We may decide to change this at a later stage: {@link setPreferredLocale}
   * anyway disallows setting unknown locales as preferred.
   */
  async getPreferredLocale(): Promise<string | undefined> {
    const locale = await Preferences.get({
      key: LocalesService.PREFERENCES_PREFFERED_LOCALE,
    });

    return locale.value ?? undefined;
  }

  /**
   * Retrieve the user's preferred locale, either from the device,
   * or from the app settings, in case it was overridden.
   */
  async getBestLocale(): Promise<AvailableLocale> {
    try {
      const [deviceLocale, preferredLocale] = await Promise.all([
        this.getDeviceLocale(),
        this.getPreferredLocale(),
      ]);

      return this.findBestLocaleGivenDeviceAndPreference(
        deviceLocale,
        preferredLocale,
      );
    } catch (e) {
      return this.defaultLocale;
    }
  }

  getBestLocaleUpdate() {
    return from(this.getBestLocale());
  }

  /**
   * Get the best locale option, according to the user's preference,
   * what the device setting is, and what we can offer.
   *
   * @param deviceLocale the locale as it comes from the device OS
   * @param preferredLocale the preferred locale of the user
   */
  findBestLocaleGivenDeviceAndPreference(
    deviceLocale: string,
    preferredLocale?: string,
  ): AvailableLocale {
    // ! Order is important!
    // The language from the user preferences has the precedence.
    const requestedLocales = [
      ...(preferredLocale ? [preferredLocale] : []),
      deviceLocale,
    ];

    // We need a copy because availableLocales is readonly, match wants mutable
    const availableLocalesCopy = [...availableLocales];

    const bestMatchLocale = match(
      requestedLocales,
      availableLocalesCopy,
      this.defaultLocale,
    );

    if (!isSupportedLocale(bestMatchLocale)) {
      throw new Error(`best match locale ${bestMatchLocale} is unsupported`);
    }

    return bestMatchLocale;
  }

  /**
   * Store in NgRx the locale provided during the initialization process,
   * either coming from the device, or from the preferences.
   */
  private async setLoadedLocaleInStore(loadedLocale: AvailableLocale) {
    const useDeviceLocale = (await this.getPreferredLocale()) === undefined;
    this.store.dispatch(
      LocalesActions.loadLocaleSuccess({ loadedLocale, useDeviceLocale }),
    );
  }

  /**
   * When we have an error loading the locales, we put it into NgRx.
   */
  private reportErrorInStore(error: unknown) {
    this.store.dispatch(LocalesActions.loadLocaleFailure({ error }));
  }

  /**
   * ! This is only meant to be called by the Angular module
   */
  async initializeTranslation(): Promise<void> {
    try {
      const locale = await this.getBestLocale();
      const localeDetails = localeLookupMap[locale];

      //Setting default global local for luxon
      Settings.defaultLocale = locale;

      // We need to register the locale data to use in pipes and directives.
      registerLocaleData(localeDetails.ngLocale);

      // Here we set a "global" variable in this service.
      // We will need it later when we set LOCALE_ID tokens.
      this.asyncLoadedLocale = locale;

      // Set the lang tag in the HTML document
      document.documentElement.lang = locale;

      // The translation is loaded conditionally, if not loaded,
      // it returns the "development" version, in en-US.
      if (localeDetails.translationFile) {
        const localeAsset = await fetch(
          `/assets/translations/${localeDetails.translationFile}.json`,
        );
        const localeAssetJson = await localeAsset.json();
        await loadTranslations(localeAssetJson.translations);
      }

      await this.setLoadedLocaleInStore(locale);
    } catch (error) {
      this.reportErrorInStore(error);
    }
  }
}
