import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalesActions } from '@yeekatee/core-data-access';
import { map } from 'rxjs';
import { LocalesService } from '../services/locales.service';

@Injectable()
export class LocalesEffects {
  reloadApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocalesActions.setPreferredLocale),
        map(async ({ locale, reload }) => {
          await this.localesService.setPreferredLocale(locale, reload);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly localesService: LocalesService,
  ) {}
}
