import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Preferences } from '@capacitor/preferences';
import { Store } from '@ngrx/store';
import { AuthUser } from '@yeekatee/client-api-angular';
import {
  AuthAmplifyHubActions,
  RequiredCognitoAttributes,
  SignInCredentials,
  SignUpCredentials,
  User,
} from '@yeekatee/core-data-access';
import { LocalesService } from '@yeekatee/i18n/data-access';
import { CodeDeliveryDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { from, throwError } from 'rxjs';
import { ulid } from 'ulid';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private signInUser?: CognitoUser;

  /**
   * Subscribe to the Amplify Hub events and dispatch actions.
   * Unsubscribe if this service is deleted.
   * @private
   */
  private readonly hubListenerCancelToken: () => void;

  constructor(
    private readonly ngZone: NgZone,
    private readonly localeService: LocalesService,
    private readonly store: Store,
  ) {
    this.hubListenerCancelToken = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
          this.ngZone.run(() =>
            this.store.dispatch(
              AuthAmplifyHubActions.signInSuccess({
                userId: (
                  Object.freeze(payload.data) as CognitoUser
                ).getUsername(),
              }),
            ),
          );
          break;
        case 'signIn_failure':
          this.ngZone.run(() =>
            this.store.dispatch(
              AuthAmplifyHubActions.signInFailure({
                error: new Error('Sign In Failure'),
              }),
            ),
          );
          break;
        case 'autoSignIn':
          this.ngZone.run(() =>
            this.store.dispatch(
              AuthAmplifyHubActions.autoSignInSuccess({
                userId: (
                  Object.freeze(payload.data) as CognitoUser
                ).getUsername(),
              }),
            ),
          );
          break;
        case 'autoSignIn_failure':
          this.ngZone.run(() =>
            this.store.dispatch(
              AuthAmplifyHubActions.autoSignInFailure({
                error: new Error('Auto Sign In Failure'),
              }),
            ),
          );
          break;
        case 'customOAuthState':
          this.ngZone.run(() =>
            this.store.dispatch(
              AuthAmplifyHubActions.customOAuthState({
                customState: payload.data,
              }),
            ),
          );
          break;
      }
    });
  }

  /**
   * Just to ensure we don't have leaks, unsubscribe from the hub to be safe.
   */
  ngOnDestroy() {
    this.hubListenerCancelToken();
  }

  /**
   * Route to the Cognito Hosted UI to sign in with Google
   */
  async googleSignIn(customState?: string) {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState,
    });
  }

  /**
   Route to the Cognito Hosted UI to sign in with Apple
   */
  async appleSignIn(customState?: string) {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Apple,
      customState,
    });
  }

  private async doHandleFederatedSignInCallback(callbackUrl: string) {
    try {
      // Workaround for https://github.com/aws-amplify/amplify-js/issues/3537
      await (Auth as any)._handleAuthResponse(callbackUrl);
    } catch (e) {
      // ? TODO Could we better handle this via NgRx effects?
      // https://github.com/aws-amplify/amplify-js/issues/3537#issuecomment-680690219
    }

    const error = new URL(callbackUrl).searchParams.get('error_description');
    if (error) throw new Error(error);

    // TODO Should we set here additional attributes, like locale?
    // TODO Should we store the last used social login?

    return this.doGetCurrentUser(false);
  }

  /**
   * Takes care of the callback URL coming from the Cognito Hosted UI,
   * to convert it into an actual auth user.
   *
   * @param callbackUrl the URL used by Cognito Hosted UI as callback
   */
  handleFederatedSignInCallback(callbackUrl: string) {
    return from(this.doHandleFederatedSignInCallback(callbackUrl));
  }

  /**
   * The best locale to sign up the user with
   *
   * @todo How to use with social log in
   */
  private async getLocale() {
    const tag = await this.localeService.getBestLocale();
    return tag.toString();
  }

  /**
   * Retrieve the groups from the ID token.
   *
   * @private
   */
  private async getGroups(): Promise<string[]> {
    const session = await Auth.currentSession();
    return session.getIdToken().payload['cognito:groups'];
  }

  private async doGetCurrentUser(bypassCache: boolean): Promise<User> {
    const user = await Auth.currentUserPoolUser({ bypassCache });

    // Start getting the current ID token here,
    // since later we may have to get attributes too in parallel.
    const groupsPromise = this.getGroups();

    // The member field 'attributes' is not exposed in the CognitoUser definition,
    // however it is at runtime. Therefore, to cope with deprecation of 'attributes'
    // we add a fallback, where we call the actual service.
    if (!user.attributes) {
      // TODO add logger message so we are aware of the deprecation.
      const info = await Auth.currentUserInfo();
      user.attributes = info.attributes;
    }

    // Now we can resolve the ID token and check the groups
    const groups = await groupsPromise;

    return {
      id: user.getUsername(),
      groups,
      ...user.attributes,
    };
  }

  /**
   * Retrieve the current user, including its groups from the ID token.
   *
   * @param refresh whether to always call Cognito, even if a local token exists
   */
  getCurrentUser(refresh = true) {
    return from(this.doGetCurrentUser(refresh));
  }

  /**
   * Retrieve the ID Token for the current session
   */
  getIdToken() {
    return from(
      Auth.currentSession().then((sessionData) => sessionData.getIdToken()),
    );
  }

  private async doSignIn(
    credentials: SignInCredentials,
  ): Promise<{ cognitoUser: CognitoUser; groups: string[] }> {
    const cognitoUser = await Auth.signIn(
      credentials.username,
      credentials.password,
    );

    const [groups] = await Promise.all([
      this.getGroups(),
      this.storeCredentials(credentials),
    ]);

    // Persist the username in local storage for the next sign in
    await this.storeCredentials(credentials);

    // We need the user for the Sign In confirmation
    this.signInUser = cognitoUser;
    return { cognitoUser, groups };
  }

  /**
   * Sign in and store the username locally
   *
   * @param credentials username and password
   */
  signIn(credentials: SignInCredentials) {
    return from(this.doSignIn(credentials));
  }

  /**
   * Use the second authentication factor to confirm the sign in.
   *
   * @todo support additional MFA types
   *
   * @param code the code received via SMS
   */
  confirmSignIn(code: string) {
    return from<Promise<CognitoUser>>(
      Auth.confirmSignIn(this.signInUser, code, 'SMS_MFA'),
    );
  }

  /**
   * Create Cognito user.
   * Set a ULID as username. The end user will be able to login via Email
   * or with a preferred username
   *
   * @param credentials the chosen email and password
   */
  private async doSignUp(credentials: SignUpCredentials) {
    const result = await Auth.signUp({
      username: ulid(),
      password: credentials.password,
      attributes: {
        email: credentials.email,
        locale: await this.getLocale(),
      },
      autoSignIn: { enabled: true },
    });

    // In local storage, persist the email as username for the next login
    await this.storeCredentials({
      username: credentials.email,
      password: credentials.password,
    });

    return result;
  }

  /**
   * Sign Up AWS Cognito
   * Setting Cognito user during initial configuration upon first login
   *
   * @param credentials
   */
  signUp(credentials: SignUpCredentials) {
    if (!credentials.password) throwError(() => new Error('Empty Password'));
    if (!credentials.email) throwError(() => new Error('Empty Email'));

    return from(this.doSignUp(credentials));
  }

  /**
   * Confirm the sign up using the code received via email
   *
   * @param username
   * @param code the one-time code received via mail
   */
  confirmSignUp(username: string, code: string) {
    return from(Auth.confirmSignUp(username, code));
  }

  /**
   * Resend the sign up verification code
   *
   * @todo We must use this in case a user began to sign up,
   *       but then failed to confirm the email, and closed the app.
   *       When they come back, they must be able to resume
   *       the confirmation process where they left.
   *
   * @param username
   */
  resendSignUp(username: string) {
    return from<Promise<string>>(Auth.resendSignUp(username));
  }

  /**
   * Sign Out from Cognito
   *
   * @param global whether to sign out on all devices
   */
  signOut(global?: boolean) {
    return from(Auth.signOut({ global }));
  }

  /**
   * The last username used to sign in
   */
  getStoredUsername() {
    return from(
      Preferences.get({ key: 'username' }).then((result) => {
        return result.value ? result.value : '';
      }),
    );
  }

  /**
   * Keep the username in local storage as a reminder for the user
   *
   * @param credentials username to store locally
   */
  async storeCredentials(credentials: SignInCredentials) {
    await Preferences.set({ key: 'username', value: credentials.username });
  }

  /**
   * Reset user's password
   * @param username
   */
  resetPassword(username: string) {
    return from<Promise<{ CodeDeliveryDetails: CodeDeliveryDetails }>>(
      Auth.forgotPassword(username),
    );
  }

  private async doConfirmPassword(
    credentials: SignInCredentials,
    code: string,
  ) {
    const result = await Auth.forgotPasswordSubmit(
      credentials.username,
      code,
      credentials.password,
    );

    await this.storeCredentials(credentials);

    return result;
  }

  /**
   * Confirm new user password
   *
   * @param credentials the email and new password
   * @param code the one-time code received via email
   */
  confirmPassword(credentials: SignInCredentials, code: string) {
    return from(this.doConfirmPassword(credentials, code));
  }

  /**
   * Set new Email to current user
   * @param email
   */
  setEmail(email: string) {
    return this.setRequiredAttribute('email', email);
  }

  /**
   * Set new phone to current user
   * @param phone
   */
  setPhoneNumber(phone: string) {
    return this.setRequiredAttribute('phone_number', phone);
  }

  /**
   * Does trigger confirm code implicitly
   *
   * @param key
   * @param value
   * @private
   */
  private async doSetRequiredAttribute(key: keyof AuthUser, value: string) {
    const user = await Auth.currentUserPoolUser();
    await Auth.updateUserAttributes(user, { [key]: value });
  }

  /**
   * Set new attribute on current user and automatically send verification code
   * @private
   * @param key
   * @param value
   */
  private setRequiredAttribute(
    key: keyof RequiredCognitoAttributes,
    value: string,
  ) {
    return from(this.doSetRequiredAttribute(key, value));
  }

  /**
   * Confirm new attribute with one-time code
   * @param attribute
   * @param code
   * @private
   */
  confirmAttribute(attribute: string, code: string) {
    return from(Auth.verifyCurrentUserAttributeSubmit(attribute, code));
  }
}
