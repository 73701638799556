import { filter, map, pipe } from 'rxjs';

/**
 * Filter out a value if it is null or undefined.
 */
export const filterNil = <T>() =>
  pipe(
    filter(
      (value: T): value is Exclude<T, undefined | null> =>
        value !== undefined && value !== null,
    ),
  );

/**
 * Filter out a value only if it is undefined.
 */
export const filterUndefined = <T>() =>
  pipe(
    filter((value: T): value is Exclude<T, undefined> => value !== undefined),
  );

/**
 * Filter out a value only if it is null.
 */
export const filterNull = <T>() =>
  pipe(filter((value: T): value is Exclude<T, null> => value !== null));

/**
 * Filter out a value if it is null or undefined in an array.
 */
export const filterArrayNil = <T>() =>
  pipe(
    map((array: (T | null | undefined)[]): T[] =>
      array.filter(
        (value): value is T => value !== null && value !== undefined,
      ),
    ),
  );
