import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthGuardActions, AuthSelectors } from '@yeekatee/core-data-access';
import { NavigationRouteNames } from '@yeekatee/shared-util-routes';
import { map, take } from 'rxjs/operators';

export const authGuard: CanActivateFn = (next, state) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(AuthSelectors.selectUser).pipe(
    take(1),
    map((user) => {
      if (user) return true;

      // The auth check failed, so we redirect to the auth page, but pass the
      // previous URL, to route to it once the user is authenticated again.
      store.dispatch(
        AuthGuardActions.redirectingToAuth({ lastRoute: state.url }),
      );

      return router.createUrlTree([NavigationRouteNames.AUTH]);
    }),
  );
};
