import { Injectable } from '@angular/core';
import { AnalyticsEvent, User } from '@yeekatee/core-data-access';
import { extractQueryParams } from '@yeekatee/shared-util-routes';
import { Analytics } from 'aws-amplify';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private isEnabled = false;

  constructor() {
    Analytics.autoTrack('pageView', {
      enable: true,
      provider: 'AWSPinpoint',
      type: 'SPA',
      getUrl: () => window.location.href,
    });

    Analytics.autoTrack('event', {
      enable: true,
      provider: 'AWSPinpoint',
    });
  }

  async configureAnalytics(user: User, lastRoute?: string) {
    const sub = user?.sub;
    const userId = user?.id;
    const userEmail = user?.email;
    const username = user?.preferred_username;
    const groups = user?.groups;

    if (!sub) throw new Error('missing user sub attribute');

    await this.updateEndpoint({
      userId: sub,
      userAttributes: {
        sub: [sub],
        yeeUserId: [userId],
        email: [userEmail],
        username: [username],
        groups,
      },
    });

    this.isEnabled = true;

    await this.trackStartSessionEvent();

    if (!lastRoute) return;

    const attributes = extractQueryParams(lastRoute, 'utm');
    if (!attributes) return;

    await this.trackCustomEvent({
      name: 'utm.params',
      attributes,
    });
  }

  async updateEndpoint(attrs: { [key: string]: unknown }) {
    return Analytics.updateEndpoint(attrs);
  }

  async trackStartSessionEvent() {
    if (this.isEnabled) await Analytics.startSession();
  }

  async trackStopSessionEvent() {
    if (this.isEnabled) await Analytics.stopSession();
  }

  /**
   * @deprecated
   */
  async trackWriteChat(userId?: string | null) {
    if (userId)
      await this.trackCustomEvent({
        name: 'writeChat',
        attributes: { userId },
      });
  }

  recordEvent(event: AnalyticsEvent) {
    // The Amplify API expects a mutable object,
    // so we need to clone the event before passing it to the API.
    const clonedEvent = structuredClone(event);

    return from(this.trackCustomEvent(clonedEvent));
  }

  private async trackCustomEvent(event: AnalyticsEvent) {
    if (!this.isEnabled) {
      throw new Error('Analytics is not enabled');
    }
    return Analytics.record(event);
  }
}
